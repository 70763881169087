import {
  Delete,
  HighlightOff,
  Mic,
  Pause,
  PlayArrow,
  Stop,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

const AudioVisualizer = () => {
  const audioContextRef = useRef(null); // Web Audio API context
  const analyserRef = useRef(null); // Analyser node
  const canvasRef = useRef(null); // Canvas for visualization
  const mediaRecorderRef = useRef(null); // MediaRecorder for recording
  const audioChunksRef = useRef([]); // Audio data chunks
  const sourceRef = useRef(null); // Source node for microphone input
  const animationIdRef = useRef(null); // Reference to animation frame
  const timerIntervalRef = useRef(null); // Reference for the timer interval

  const [isRecording, setIsRecording] = useState(false); // Recording state
  const [isPaused, setIsPaused] = useState(false); // Pause state
  const [audioBlob, setAudioBlob] = useState(null); // Audio Blob for download or use
  const [hasError, setHasError] = useState(null); // Error state
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256; // FFT size for frequency analysis

        // Create source from the microphone input
        sourceRef.current =
          audioContextRef.current.createMediaStreamSource(stream);
        sourceRef.current.connect(analyserRef.current);

        // Initialize MediaRecorder
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data); // Store audio data chunks
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          setAudioBlob(audioBlob);
          setRecordings((prev) => [...prev, audioBlob]); // Set the recorded audio blob
          audioChunksRef.current = []; // Reset chunks for the next recording
        };

        // Start visualization
        // visualize();
      } catch (error) {
        setHasError(
          "Error accessing microphone. Please ensure microphone permissions are granted."
        );
        console.error("Error initializing audio:", error);
      }
    };

    initAudio();

    // Cleanup audio context on component unmount
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      cancelAnimationFrame(animationIdRef.current); // Stop any animations
      clearInterval(timerIntervalRef.current); // Stop the timer
    };
  }, []);

  const visualize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    const analyser = analyserRef.current;
    if (!analyser) return;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationIdRef.current = requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      // Clear the canvas
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      // Set bar width and spacing
      const barWidth = 2; // Thin bars
      const totalBarWidth = barWidth * bufferLength; // Total width of all bars
      const offsetX = (canvas.width - totalBarWidth) / 2; // Center the bars horizontally

      const centerY = canvas.height / 2; // The center line for the bars

      let x = offsetX; // Start drawing from the offset to center the bars

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2; // Scale the bar height

        // Set the color of the bars
        canvasCtx.fillStyle = `rgb(50, 200, ${barHeight + 100})`;

        // Draw the bar above and below the center line
        canvasCtx.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);

        // Move to the next bar position
        x += barWidth + 1; // Add 1px space between bars
      }
    };

    draw();
  };

  const startRecording = () => {
    if (mediaRecorderRef.current && !isRecording) {
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsPaused(false);
      setTimer(0); // Reset timer
      startTimer(); // Start the timer
      visualize();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      cancelAnimationFrame(animationIdRef.current); // Stop visualization when recording stops
      clearInterval(timerIntervalRef.current); // Stop the timer
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      cancelAnimationFrame(animationIdRef.current); // Pause the visualization
      clearInterval(timerIntervalRef.current); // Pause the timer
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer(); // Resume the timer
      visualize(); // Resume the visualization
    }
  };

  const clearRecording = () => {
    setAudioBlob(null);
  };

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000); // Increment timer every second
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="d-flex flex-column  align-items-center">
      {" "}
      <div
        style={{
          background: "white",
          width: "100%",
          boxShadow: "0px 20px 50px 0px var(--primary-accent-color)",
        }}
        className=" row row-cols-3 mb-4 w-100 rounded-bottom border-top py-2"
      >
        {hasError && <p style={{ color: "red" }}>{hasError}</p>}

        <div className="d-flex justify-content-center align-items-center">
          <canvas
            ref={canvasRef}
            style={{
              height: "30px",
              width: "100%",
            }}
          ></canvas>
        </div>

        <div className="d-flex justify-content-center gap-2 align-items-center">
          {!isRecording && (
            <IconButton onClick={startRecording}>
              <Mic />
            </IconButton>
          )}

          {isRecording && (
            <Button
              className=" rounded-3 px-2"
              sx={{
                backgroundColor: "red",
                textTransform: "capitalize",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              
              onClick={stopRecording}
            >
             <Stop  /> Stop
            </Button>
          )}
          {isRecording && !isPaused && (
            <Button
              className=" rounded-3 px-2"
              
              sx={{
                backgroundColor: "#EAECF0",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "#EAECF0",
                },
              }}
             
              onClick={pauseRecording}
            >
              <Pause  />Pause
            </Button>
          )}
          {isRecording && isPaused && (
            <Button
              className=" rounded-3 px-2 "
              sx={{
                backgroundColor: "#EAECF0",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "#EAECF0",
                },
              }}
             
              onClick={resumeRecording}
            >
             <PlayArrow  /> Resume
            </Button>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {isRecording && <>{formatTime(timer)}</>}{" "}
          {/* {audioBlob && (
            <Button
              sx={{
                backgroundColor: "white",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              className=" rounded-3"
              startIcon={<HighlightOff />}
              onClick={clearRecording}
            >
              Cancel
            </Button>
          )} */}
        </div>
      </div>
      <div className="w-100 border rounded-3 p-2 ">
        <div className="d-flex justify-content-between align-items-center">
          <h6>Uploaded Files</h6>
          <div className="d-flex justify-content-between align-items-center">
            <Button
              className=" rounded-3"
              sx={{
                backgroundColor: "#EAECF0",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "#EAECF0",
                },
              }}
            >
              Transcribe All
            </Button>
            <Button
              className=" rounded-3"
              sx={{
                backgroundColor: "#EAECF0",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "#EAECF0",
                },
              }}
            >
              Remove All
            </Button>
          </div>
        </div>
        {recordings.map((recording, index) => (
          <div key={index}>
            <audio
              style={{
                borderRadius: "10px",
              }}
              controls
            >
              <source src={URL.createObjectURL(recording)} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AudioVisualizer;
