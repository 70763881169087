import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { useAuth } from "../../auth";
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import ClinicalClientDetailsTableRowClinicalRoute from "./tables/ClinicalClientDetailsTableRowClinicalRoute";
import ClientDetailsViewClinicalRoute from "./component/clients/ClientDetailsViewClinicalRoute";
import { Box, Breadcrumbs, Link, Pagination, PaginationItem, Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import { NoDataSvg } from "./clinicalNotesClinicalRoute/ClinicalNoteSVGs";



dayjs.extend(localizedFormat);

const ExpertClinicalClientDB = () => {
  const { currentUser } = useAuth();
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();
  const location = useLocation();
  const storedData = localStorage.getItem("Authorization");
  const token = storedData ? storedData?.split("Bearer ")[1] : null;
  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
    token: token,
  };

  let userID;



  // Check if user is logged in
  useEffect(() => {
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      navigate("/auth")
    } else {
      userID = JSON.parse(localStorage.getItem("profile")).UserId;
    }
  }, [location, navigate]);

  //Use effect to open Client Details
  useEffect(() => {
    if (
      window.location.href.split("?")[1] !== undefined &&
      window.location.href.split("?")[1].includes("id=")
    ) {
      const searchParams = new URLSearchParams(location.search);

      window.location.href.split("?")[1].includes("id=");
      const clientIDFromURL = searchParams.get("id");
      const clientNameFromURL = searchParams.get("client");



      setShowClientDetails(true);
      let [clientItem] = clientList?.filter(
        (item) => item.user_id === clientIDFromURL
      );

      setClientDetails({
        id: clientItem?.user_id,
        name: clientItem?.name || clientNameFromURL,
        image: clientItem?.image,
        email: clientItem?.email,
      });

      //console.log("clientItem", clientItem);
    } else {
      setShowClientDetails(false);
    }
  }, [window.location.search, clientList]);


  //Fetch Clients
  useEffect(() => {
    if (!currentUser) return

    const postClientsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list-clients?professional_id=${currentUser?.UserId}&count=10&page=${page || 1}`,
          {
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        
        const clients = await postResponse.data;
        setClientList(jwtDecode(clients));
        setTotalPages(postResponse.total_pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    postClientsData();
  }, [page]);

  const handleRowClick = (
    name, id, email, sessions, image
  ) => {
    console.log(name, id, email, sessions, image);
    const queryParam = `client=${name}&id=${id}`;
    const currentPath = window.location.pathname;
    const updatedPath = `${currentPath}?${queryParam}`;

    setClientDetails({
      name: name, id: id, email: email, sessions: sessions, image: image
    });
    navigate(updatedPath);
    setShowClientDetails(true);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };





  return (
    <>
      <div className="my-3 ClientDashboard">
        <div className="ClientDashboard--Header d-flex">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                onClick={() => { navigate("/clinical/clients"); setClientDetails({}) }}
                role="button"
                id="clients-breadcrumb"
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  textDecoration: "none",
                  color: "text.primary",
                }}
                className={`d-flex align-items-center justify-content-between gap-2 ${clientDetails?.name && "text-secondary"}`}
              >
                Clients
              </Link>

              {clientDetails?.name && <Typography
                sx={{ fontWeight: "500", fontSize: "20px" }}
                color="text.primary"
              >
                {clientDetails?.name}
              </Typography>}
            </Breadcrumbs>
          </div>
        </div>
        {showClientDetails &&
          <ClientDetailsViewClinicalRoute
            clientDetails={clientDetails}

          />
        }

        <>
          {!showClientDetails &&
            (isLoading ? (
              <TableContainer className=" border rounded-3 " sx={{ marginTop: "20px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Profile</TableCell> */}
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Sessions</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(new Array(10)).map((_, index) => (
                      <TableRow
                        key={index}
                        className={`ClientDetails--TableRow`}
                        sx={{
                          backgroundColor: "#FFF",
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="Table--Name">
                          <div>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="Table--Sessions">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "15vw" }}
                          />
                        </TableCell>
                        <TableCell className="Table--Sessions">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "5vw" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <div className="Table--Actions__Alt d-flex">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <TableContainer className=" border rounded-3 " sx={{ marginTop: "20px" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Profile</TableCell> */}
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Sessions</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientList?.length === 0 ? (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className="NoDataAvailable">
                              {NoDataSvg}
                              <p>No Clients Found</p>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                        </TableRow>
                      ) : (
                        clientList.map((item) => (
                          <ClinicalClientDetailsTableRowClinicalRoute
                            key={item.user_id}
                            id={item.user_id}
                            name={item.name}
                            email={item.email}
                            image={item.image}
                            sessions={item.session_count}
                            onClickCallback={handleRowClick}
                          />
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="d-flex justify-content-end mb-5 p-4">
                  <Box
                    sx={{
                      "& .MuiPaginationItem-root": {
                        "&.Mui-selected": {
                          backgroundColor: "var(--primary-color)", // Selected page background color
                          color: "#fff", // Selected page text color
                        },
                       
                      },
                    }}
                  >
                    <Pagination
                      count={totalPages ? totalPages : 1}
                      page={page}
                      onChange={handleChange}
                      renderItem={(item) => <PaginationItem {...item} />}
                    />
                  </Box>
                </div>
              </>
            ))}

        </>
      </div>

    </>
  );
};

export default ExpertClinicalClientDB;
