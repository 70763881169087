import { ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../context";
import { AppendToStella } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../auth";
import AuditLogs from "./AuditLogs";
import { set } from "date-fns";
import DownloadPrescription from "../Dialogs/DownloadPrescription";

function Actions({ downloadPDF }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [auditOpen, setAuditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const enqueueSnackbar = useSnackbar();
  const restricteddownload = ["US", "AU"];
  const [downloadPrescription, setDownloadPrescription] = useState(false);

  const { currentUser } = useAuth();
  const toggleDrawer = (newOpen) => {
    setAuditOpen(newOpen);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRefresh = () => {
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      askStellaConversation: "init",
    }));
    handleClose();
    AppendToStella({
      ...expertClinicalNotesData?.headerData,
    }).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          askStellaConversation: "mid",
        }));
        return;
      }
      setExpertClinicalNotesData((prevData) => ({
        ...prevData,
        askStellaConversation: "end",
      }));
    });
  };
  const handleEditTemplate = () => {
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      editTemplate: true,
    }));
    handleClose();
  };
  const handleSaveTemplate = () => {
    const newUpdatedTemplate = expertClinicalNotesData?.data?.template?.map(
      (item) => item.name
    );
    const previousTemplate = expertClinicalNotesData?.template?.map(
      (item) => item.name
    );
    const updatedTemplate = newUpdatedTemplate.filter(
      (item) => !previousTemplate.includes(item)
    );
    const updatedTemplate2 = previousTemplate.filter(
      (item) => !newUpdatedTemplate.includes(item)
    );

    if (updatedTemplate?.length > 0) {
      updatedTemplate?.map((item) => {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "visibility_changed",
            details: {
              field_name:
                item?.charAt(0)?.toUpperCase() +
                item?.slice(1)?.replace(/_/g, " "),
              visibility: "visible",
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      });
    }
    if (updatedTemplate2?.length > 0) {
      updatedTemplate2?.map((item) => {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "visibility_changed",
            details: {
              field_name:
                item?.charAt(0)?.toUpperCase() +
                item?.slice(1)?.replace(/_/g, " "),
              visibility: "hidden",
            },
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      });
    }
    expertClinicalNotesData.socketRef.current.send(
      JSON.stringify({
        header: expertClinicalNotesData?.headerData,
        session_data: expertClinicalNotesData?.data,
      })
    );
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      editTemplate: false,
    }));

    handleClose();
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        size="small"
        style={{
          color: "#000",
          boxShadow: "none",
          fontSize: "16px",
          fontWeight: "500",
          textTransform: "capitalize",
        }}
        className=" text-capitalize border rounded-2 border-2 "
      >
        Actions {open ? <ExpandLess /> : <ExpandMore />}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>None</MenuItem> */}
        {/* {!expertClinicalNotesData?.data?.submitted &&
          expertClinicalNotesData?.headerData?.user_id && (
            <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
          )} */}
        {/* hidden needs to update the new fields */}
        {!restricteddownload?.includes(currentUser?.Country) &&
          expertClinicalNotesData?.headerData?.user_id && (
            <MenuItem
              onClick={() => {
                handleClose();
                downloadPDF();
              }}
            >
              Download PDF
            </MenuItem>
          )}
        {expertClinicalNotesData?.headerData?.user_id && (
          <MenuItem
            onClick={() => {
              handleClose();
              toggleDrawer(true);
            }}
          >
            Audit Trail
          </MenuItem>
        )}
        {/* Commented To unhide */}
        {/* {!expertClinicalNotesData?.data?.submitted &&
          expertClinicalNotesData?.headerData?.user_id && (
            <MenuItem onClick={handleEditTemplate}>Edit Template</MenuItem>
          )} */}

        {/* {expertClinicalNotesData?.headerData?.user_id && (
          <MenuItem
            onClick={() => {
              handleClose();
              setDownloadPrescription(true);
            }}
          >
            Download Prescription
          </MenuItem>
        )} */}
      </Menu>
      <Drawer
        open={auditOpen}
        onClose={() => {
          toggleDrawer(false);
        }}
      >
        <Box className="p-2 p-md-4 audit-drawer " role="presentation">
          <AuditLogs
            close={() => {
              toggleDrawer(false);
            }}
          />
        </Box>
      </Drawer>
      <DownloadPrescription
        open={downloadPrescription}
        close={() => setDownloadPrescription(false)}
      />
      {expertClinicalNotesData?.editTemplate && (
        <Tooltip arrow title="Save Template">
          <IconButton
            onClick={handleSaveTemplate}
            style={{ bottom: "80px", zIndex: "1000", background: "white" }}
            className=" position-fixed border shadow  end-0 mx-5 "
          >
            <Save sx={{ fontSize: "50px", color: "var(--primary-color)" }} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default Actions;
