import { useState, useEffect } from "react";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate } from "react-router-dom";
import StellaMode from "./tabs/settings/StellaMode";
import EditClinicalProfile from "./tabs/settings/EditClinicalProfile";
import Credits from "./tabs/settings/Credits";
import { useAuth } from "../../auth";
import { Breadcrumbs, Link, Tooltip, Typography } from "@mui/material";
import { HelpOutline, InfoOutlined, QrCode } from "@mui/icons-material";
import MerchantQr from "./clinicalNotesClinicalRoute/Components/MerchantQr";

const ExpertClinicalSettingsDB = () => {
  const [selectedOption, setSelectedOption] = useState("Edit Profile");
  const [userData, setUserData] = useState({});
  const [openQrModal, setOpenQrModal] = useState(false);
  const { currentUser } = useAuth();
  const handleOption = (option) => {
    setSelectedOption(option);
  };
  const localView = new URLSearchParams(
    new URL(window.location.href).search
  ).get("view");

  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile"))?.UserId;
  const userEmail = JSON.parse(localStorage.getItem("profile"))?.Emailid;

  const navigate = useNavigate();



  // Check if user is logged in
  useEffect(() => {
    if (!currentUser) return

    setSelectedOption(localView == null ? "Edit Profile" : localView);
  }, []);

  console.log(localView);
  //Professionals
  useEffect(() => {
    if (!currentUser) return
    const payload = {
      user_id: userID,
      email: userEmail,
      screen: null,
    };


    const postData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/professionals/get`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        setUserData(postResponse?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postData();
  }, []);

  console.log("userData", userData);

  return (
    <div className="my-3 SettingsDashboard">
      <div className="ExpertSettingsDashboard--Header mb-3">
        <div className="d-flex justify-content-between" role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                navigate("/clinical/settings?view=Edit Profile");
                setSelectedOption("Edit Profile");
              }}
              role="button"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              id="settings"
              className="settings d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Settings
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              {selectedOption}
            </Typography>
          </Breadcrumbs>
          {/* <div onClick={() => setOpenQrModal(!openQrModal)} className="p-2  rounded" style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#EEEEEE", marginLeft: "10px", cursor: "pointer" }}>
            <QrCode />
          </div> */}
        </div>
      </div>

      <div style={{ overflowY: "auto" }} className=" ">
        <div
          style={{ background: "#EEEEEE", width: "390px", }}
          className="d-flex flex-wrap mt-2 gap-1 p-1 rounded-pill"
        >
          <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedOption === "Edit Profile" ? "white" : "",
              border: 0,
              color: selectedOption === "Edit Profile" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="settings_edit_profile"
            className={`settings_edit_profile py-1 px-4 rounded-pill `}
            onClick={() => { handleOption("Edit Profile"); navigate("?view=Edit Profile") }}
          >
            Edit Profile
          </div>
          {/* <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedOption === "Stella Mode" ? "white" : "",
              border: 0,
              color: selectedOption === "Stella Mode" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="settings_stella_mode"
            className={`settings_stella_mode py-1  px-4 rounded-2 `}
            onClick={() => { handleOption("Stella Mode"); navigate("?view=Stella Mode") }}
          >
            Stella Mode
          </div> */}
          <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedOption === "Credits" ? "white" : "",
              border: 0,
              color: selectedOption === "Credits" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="settings_credits"
            className={`settings_credits py-1 px-4 rounded-pill `}
            onClick={() => { handleOption("Credits"); navigate("?view=Credits") }}
          >
            Credits
          </div>
          <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedOption === "Notes Template" ? "white" : "",
              border: 0,
              color: selectedOption === "Notes Template" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="settings_note_template"
            className={`settings_note_template py-1 px-4 rounded-pill `}
            onClick={() => {
              handleOption("Notes Template");
              window.location.href = `https://myaccount${process.env.REACT_APP_CLIENT_DOMAIN}/onboarding/home/profession/select-template/?redirect=${window.location.origin}/auth&userType=repeat_user`
            }}
          >
            Notes Template
          </div>

        </div>
      </div>


      <div className="ExpertSettingsDashboard--Content p-0 p-md-4">
        <h4 className="d-flex gap-2 align-items-center">{selectedOption}
          {selectedOption === "Credits" && <Tooltip
            arrow
            title={
              <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                Your credit transaction history is listed here
              </p>
            }
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.black",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                  color: "common.white",
                  padding: "10px",
                },
              },
            }}
          >
            <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />
          </Tooltip>}</h4>
        {selectedOption === "Edit Profile" && (
          <EditClinicalProfile data={userData?.profile} />
        )}
        {/* {selectedOption === "Stella Mode" && <StellaMode />} */}

        {selectedOption === "Credits" && <Credits />}
        <MerchantQr open={openQrModal} close={() => setOpenQrModal(false)} />
      </div>
    </div>
  );
};

export default ExpertClinicalSettingsDB;
