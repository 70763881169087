import React, { useContext, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { AppContext } from "../../../../context";
import { MicIconSVG, NotePadSVG } from "../ClinicalNoteSVGs";
import { FileUploader } from "react-drag-drop-files";
import "../../../../styles/dialogs/FloatingService.css";

// Component
import AssistantModal from "./AssistantModal";

// API
import {
  AppendToContext,
  InsertRagContext,
  LAMA_OCR_Document,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { convertBlobToBase64 } from "../lib/utils";
import { fetchSTTWhisper } from "../../APIS/clientAPIS";

// MUI

import {
  AssistantOutlined,
  Close,
  CloudUploadOutlined,
  ContentCopy,
  Delete,
  DeleteOutlineOutlined,
  Loop,
  Mic,
  ReportProblemOutlined,
  Stop,
  TramSharp,
} from "@mui/icons-material";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import DisclaimerModal from "../../modals/DisclaimerModal";
import AcceptCCOPDisclaimer from "../../modals/AcceptCCOPDisclaimer";
import { acceptTNC, getDisclaimer, getTNC } from "../../../core/_request";
import { useAuth } from "../../../../auth";

const LinearProgressBarWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const FloatingService = ({ screen, data }) => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const { currentUser, logout } = useAuth();
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [assistantModalOpen, setAssistantModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const [transcriptionValue, setTranscriptionValue] = useState("");
  const [uploadFileDetail, setUploadFileDetail] = useState();
  const [uploadFilePercent, setUploadFilePercent] = useState();
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const lastTranscript = useRef("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const [disclaimercontent, setDisclaimercontent] = useState("");
  const [tncDialog, setTncDialog] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);


  // speech recognization new implementation

  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    HandleDisclaimerFromAPI()
  }, [])


  const startRecording = async () => {
    setTranscription(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' });
    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = async () => {

      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm;codecs=opus' });
      audioChunksRef.current = [];
      await sendAudioToApi(audioBlob);
      // Send the new audioBlob after stopping the recording
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const sendAudioToApi = async (blob) => {
    try {
      const base64String = await convertBlobToBase64(blob);
      const formattedBase64 = String(base64String).split("data:audio/webm;codecs=opus;base64,")[1];
      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({ base64_audio: formattedBase64 })
        );
        setTranscriptionValue(transcriptionValue + " " + whisperTranscription);
        setTranscription(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // speech recognization end  implementation



  const openCustomModal = () => {
    modalRef.current.className = "Notetaker--Modal Modal--Open";
    // setTranscriptionValue("");
  };

  const closeCustomModal = () => {
    // setTranscriptionValue("");
    modalRef.current.className = "Notetaker--Modal";
  };

  const handleOpen = () => {
    setAssistantModalOpen(true);
  };
  const handleClose = () => {
    setAssistantModalOpen(false);
  };

  const handleSendData = () => {
    if (data?.headerData || data?.session_data?.appointment_details) {


      setExpertClinicalNotesData((prevData) => ({
        ...prevData,
        askStellaConversation: "init",
      }));
      if (screen === "SessionManagement" || screen === "SessionNotes") {
        InsertRagContext({
          ...expertClinicalNotesData?.headerData,
          transcript: transcriptionValue,
        }).then((res) => {
          if (res?.error) {
            enqueueSnackbar(res?.message, { variant: "info" });

            setFetching(false);
            setExpertClinicalNotesData((prevData) => ({
              ...prevData,
              askStellaConversation: "mid",
            }));
            return;
          }

          expertClinicalNotesData.socketRef.current.send(JSON.stringify(
            {
              _id: expertClinicalNotesData?.headerData?.session_id,
              raw_data: transcriptionValue,
              user_id: expertClinicalNotesData?.headerData?.user_id,
              expert_id: expertClinicalNotesData?.headerData?.expert_id,
              state: "In Progress"
            }
          ));
          setTimeout(() => {
            const dropdownData = ["academic_functioning", "employment_status", "marital_functioning", "marital_status", "social_functioning"]
            const template = expertClinicalNotesData?.data?.template?.map((it) => ({ name: dropdownData.includes(it?.name) ? "dropdown" : it?.name }))
            console.log(template);

            AppendToContext({
              ...expertClinicalNotesData?.headerData,
              transcript: transcriptionValue,
              ordered_fields_json: template
            }).then((res) => {
              if (res?.error) {
                enqueueSnackbar(res?.message, { variant: "info" });

                setFetching(false);
                setExpertClinicalNotesData((prevData) => ({
                  ...prevData,
                  askStellaConversation: "mid",
                }));
                return;
              }
              setExpertClinicalNotesData((prevData) => ({
                ...prevData,
                askStellaConversation: "end",
              }));
            });
          }, 10000);
          setTimeout(() => {

            setFetching(false);
            enqueueSnackbar("Your note has been submitted successfully", {
              variant: "success",
            });
          }, 10000);
          closeCustomModal();
          setTranscriptionValue("");
        });
      }
    }
  };

  const appendOCRText = (ocrText) => {
    setTranscriptionValue((prev) => prev + ocrText);
    lastTranscript.current += ocrText; // Update the reference to include OCR text
    setTranscribing(false);
  };

  const handleFileChange = (files) => {
    setLoading(true);
    const file = files[0];
    setUploadFileDetail(file);
    if (file) {
      //setTranscribeSaveBtnActive(false);

      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      if (file.size > 20 * 1024 * 1024) {
        // Convert 20 MB to bytes
        enqueueSnackbar("File size should be less than 20 MB", {
          variant: "error",
        });
        return;
      }
      if (allowedTypes.includes(file.type)) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setUploadFilePercent(percent);
          },
          (err) => console.log(err),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setSelectedFile(url);
              setLoading(false);
            });
          }
        );
      } else {
        console.error("Invalid file type. Please select an image or PDF file.");
        enqueueSnackbar(
          "Invalid file type. Please select an image or PDF file.",
          { variant: "error" }
        );
        setLoading(false);
      }
    } else {
      console.error("No file selected");
      setLoading(false);
    }
  };

  const handleTranscribeClick = async () => {
    if (selectedFile) {
      setTranscribing(true);
      try {
        if (expertClinicalNotesData.socketRef.current) {
          expertClinicalNotesData.socketRef.current.send(
            JSON.stringify({
              action_type: "uploaded",
              details: {
                name: uploadFileDetail.name,
                link: selectedFile,
              },
              user_id: expertClinicalNotesData?.headerData?.user_id,
              expert_id: expertClinicalNotesData?.headerData?.expert_id,
            })
          );
        }
        LAMA_OCR_Document(selectedFile).then((res) => {
          if (res?.error) {
            enqueueSnackbar(res?.message, { variant: "info" });
            return;
          }
          setStep(1);
          appendOCRText(res?.response);
          setSelectedFile(null);
          setUploadFilePercent(0);
        });
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    }
  };

  const clearContent = () => {
    inputRef.current.value = "";
    setTranscriptionValue("");
  };

  const copyContent = () => {
    navigator.clipboard.writeText(transcriptionValue);
    enqueueSnackbar("Text copied!", {
      variant: "success",
    });
  };

  const HandleDisclaimerFromAPI = async () => {
    const response = await getDisclaimer();
    const parser = new DOMParser();
    const doc = parser.parseFromString(response, "text/html");
    const contentDiv = doc.querySelector(".theme-doc-markdown.markdown");
    const encryptedEmails = contentDiv.querySelectorAll(".__cf_email__");
    encryptedEmails.forEach((element) => {
      element.innerHTML =
        `<a  href="mailto:${process.env.REACT_APP_CLIENT_EMAIL}">${process.env.REACT_APP_CLIENT_EMAIL}</a>`;
    });
    if (contentDiv) {
      setDisclaimercontent(contentDiv.innerHTML);
    } else {
      const fallbackMessage = `
                        Disclaimer. 
                        <a href="https://legal${process.env.REACT_APP_CLIENT_DOMAIN}/disclaimer-ccop" target="_blank">Read more</a>`;
      document.getElementById("disclaimer-content").innerText = fallbackMessage;
      setDisclaimercontent(fallbackMessage);
    }
  };

  const acceptTnc = async () => {
    const tnc = await acceptTNC(currentUser?.UserId);
    if (tnc?.success) {
      setTncDialog(false);
      checkTnc();
    } else {
      setFetching(false);
      enqueueSnackbar("Error accepting T&C.", { variant: "error" });
    }
  };
  const checkTnc = async () => {
    setFetching(true);
    const tnc = await getTNC(currentUser?.UserId);
    if (tnc?.success) {
      if (tnc?.status) {
        // If user accepted Disclaimer check caution
        checkDisclaimer();
      } else {
        // If user not accepted Disclaimer show Disclaimer dialog
        window.localStorage.removeItem("disclaimer");
        setTncDialog(true);
      }
      // fetchNotification()
    } else {
      setFetching(false);
      enqueueSnackbar("Error fetching disclaimer status.", {
        variant: "error",
      });
    }
  };
  const checkDisclaimer = () => {

    const disclaimerStatus = JSON.parse(
      window.localStorage.getItem("disclaimer")
    )?.status;
    if (!disclaimerStatus) {
      // If user not accepted caution show caution dialog
      setDisclaimer(true);
    } else {
      handleSendData()
    }
  };

  return (
    <>
      <div className="floatingContainer">
        {!expertClinicalNotesData?.data?.submitted && (
          <div
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
            onClick={handleOpen}
            style={{
              background:
                expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                  0
                  ? "#F63D68"
                  : expertClinicalNotesData?.data?.assistant?.medications
                    ?.alert_number > 0
                    ? "#FB6514"
                    : expertClinicalNotesData?.data?.assistant?.diagnostics
                      ?.alert_number > 0
                      ? "#FDB022"
                      : "#12B76A",
              width: "fit-content",
            }}
          >
            <div
              className="bg-white rounded-circle p-1"
              style={{ width: "34px", height: "34px" }}
            >
              {expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                0 ? (
                <ReportProblemOutlined
                  fontSize="medium"
                  sx={{ fill: "#F63D68" }}
                />
              ) : expertClinicalNotesData?.data?.assistant?.medications
                ?.alert_number > 0 ? (
                <img width={20} src="/assets/img/elements/medication.png" />
              ) : expertClinicalNotesData?.data?.assistant?.diagnostics
                ?.alert_number > 0 ? (
                <img width={20} src="/assets/img/elements/diagnostics.png" />
              ) : (
                <AssistantOutlined fontSize="medium" sx={{ fill: "#12B76A" }} />
              )}
            </div>
            {isHovered1 &&
              (expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.alerts
                      ?.alert_number
                  }{" "}
                  Alerts
                </span>
              ) : expertClinicalNotesData?.data?.assistant?.medications
                ?.alert_number > 0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.medications
                      ?.alert_number
                  }{" "}
                  Medication
                </span>
              ) : expertClinicalNotesData?.data?.assistant?.diagnostics
                ?.alert_number > 0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.diagnostics
                      ?.alert_number
                  }{" "}
                  Diagnostic
                </span>
              ) : (
                <span className="assistantText">Assistant</span>
              ))}
          </div>
        )}

        {expertClinicalNotesData?.data?.submitted && (
          <div
            id="assistant"
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
            onClick={handleOpen}
            style={{
              background: "#12B76A",
              width: "fit-content",
            }}
          >
            <div className="bg-white rounded-circle p-1">
              <AssistantOutlined fontSize="medium" sx={{ fill: "#12B76A" }} />
            </div>
            {isHovered1 && <span className="assistantText">Assistant</span>}
          </div>
        )}
        {!expertClinicalNotesData?.data?.submitted && (
          <button
            id="generate-note-via-voice-document"
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            onClick={openCustomModal}
            style={{
              background: "var(--primary-color)",
              width: "fit-content",
              color: "#fff",
              border: "none",
            }}
          >
            {MicIconSVG}
            {isHovered2 && (
              <span className="assistantText">
                Generate note via voice/document
              </span>
            )}
          </button>
        )}
      </div>

      <AssistantModal show={assistantModalOpen} close={handleClose} />
      {fetching && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "rgba(249,245,255,0.47)",
            zIndex: 100000,
            top: 0,
            left: 0,
          }}
        >
          {" "}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}

          >

            <img
              alt=""
              src="/assets/img/icons/aisparkle.png"
              width={50}
              className="animate-pulse"
            />
          </div>

        </div>
      )}
      {/* Custom Modal */}
      <div className=" Notetaker--Modal" id="customModal" ref={modalRef}>
        {(step === 1 && (
          <div className="Notetaker--Content">
            <div className="Notetaker--Header d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">

              <h6 className="d-flex align-items-center justify-content-between">{process.env.REACT_APP_CLIENT_DISPLAY_NAME}   <IconButton className="d-lg-none" onClick={closeCustomModal}>
                <Close />
              </IconButton></h6>
              <div className="d-flex align-items-center gap-2 gap-md-3">
                <Button
                  className="PrimaryCTA---ALT"
                  onClick={() => {
                    setStep(2);

                  }}
                  id="notetaker_uplaod_document_for_transcription"
                >
                  <CloudUploadOutlined />
                  Upload
                </Button>
                <Button
                  className="PrimaryCTA"
                  onClick={checkTnc}
                  disabled={transcribing || transcriptionValue?.trim() === ""}
                  id="notetaker_send_transcription"
                >
                  <img
                    width={24}
                    src={"/assets/img/clinical_notes_assets/Frame-submit.png"}
                    alt="submit"
                  />
                  Send Transciption
                </Button>
                <div>
                  <IconButton sx={{ minWidth: "52px" }} className="d-none d-lg-block" onClick={closeCustomModal}>
                    <Close />
                  </IconButton>
                </div>

              </div>
            </div>
            <div className="Notetaker--Body">
              <div className="my-3">
                <div
                  className="Notetaker--NoteArea"
                  style={{ position: "relative" }}
                >
                  {transcription && (
                    <div
                      style={{
                        color: "lightblue",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "white",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2.5em"
                        height="2.5em"
                        viewBox="0 0 24 24"
                      >
                        <circle cx={12} cy={12} r={0} fill="currentColor">
                          <animate
                            id="svgSpinnersPulseMultiple0"
                            fill="lightblue"
                            attributeName="r"
                            begin="0;svgSpinnersPulseMultiple2.end"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="0;11"
                          ></animate>
                          <animate
                            fill="lightblue"
                            attributeName="opacity"
                            begin="0;svgSpinnersPulseMultiple2.end"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="1;0"
                          ></animate>
                        </circle>
                        <circle cx={12} cy={12} r={0} fill="currentColor">
                          <animate
                            id="svgSpinnersPulseMultiple1"
                            fill="lightblue"
                            attributeName="r"
                            begin="svgSpinnersPulseMultiple0.begin+0.2s"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="0;11"
                          ></animate>
                          <animate
                            fill="lightblue"
                            attributeName="opacity"
                            begin="svgSpinnersPulseMultiple0.begin+0.2s"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="1;0"
                          ></animate>
                        </circle>
                        <circle cx={12} cy={12} r={0} fill="currentColor">
                          <animate
                            id="svgSpinnersPulseMultiple2"
                            fill="lightblue"
                            attributeName="r"
                            begin="svgSpinnersPulseMultiple0.begin+0.4s"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="0;11"
                          ></animate>
                          <animate
                            fill="lightblue"
                            attributeName="opacity"
                            begin="svgSpinnersPulseMultiple0.begin+0.4s"
                            calcMode="spline"
                            dur="1.2s"
                            keySplines=".52,.6,.25,.99"
                            values="1;0"
                          ></animate>
                        </circle>
                      </svg>
                    </div>
                  )}
                  <textarea
                    ref={inputRef}
                    variant="default"
                    id="speakArea"
                    label="Speak Now"
                    style={{
                      width: "100%",
                      resize: "none",
                      border: "none",
                    }}
                    onChange={(e) => {
                      // console.log("Manual edit: ", e.target.value);
                      setTranscriptionValue(e.target.value);
                    }}
                    className="SpeechTextArea"
                    value={transcriptionValue}
                    multiline
                    rows={16}
                    cols={16}
                    fullWidth
                  />
                  <div className="Notetaker--Options">
                    <button style={{ background: "white" }} onClick={copyContent}>
                      <ContentCopy />
                    </button>

                    <button style={{ background: "white" }} onClick={clearContent}>
                      <DeleteOutlineOutlined />
                    </button>
                    <div
                      className="d-block d-lg-none translate-middle-x  "
                      style={{ position: "absolute", left: "50%", top: "" }}
                    >

                      {isRecording ? (
                        <>
                          <IconButton
                            className="StartTranscription--Button__ALT bg-transparent mt-3"
                            style={{
                              zIndex: "100",
                            }}
                            sx={{ padding: 0, }}
                            onClick={stopRecording}
                          >
                            <div
                              style={{
                                background: "#dfe3ff",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Stop style={{ color: "var(--primary-color)" }} />
                            </div>
                          </IconButton>

                        </>
                      ) : (
                        <IconButton
                          className="StartTranscription--Button mt-3"
                          // style={{ height: "100px", width: "100px" }}
                          sx={{ padding: 0 }}
                          onClick={startRecording}
                        >
                          <div
                            style={{
                              background: "var(--primary-color)",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Mic style={{ color: "#FFF" }} />
                          </div>
                        </IconButton>
                      )}


                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-none d-lg-flex justify-content-center  "
              >
                {isRecording ? (
                  <>
                    <IconButton
                      className="StartTranscription--Button__ALT bg-transparent mt-3"
                      style={{
                        zIndex: "100",
                      }}
                      sx={{ padding: 0, }}
                      onClick={stopRecording}
                      id="notetaker_stop_audio_recording"
                    >
                      <div
                        style={{
                          background: "#dfe3ff",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Stop style={{ color: "var(--primary-color)" }} />
                      </div>
                    </IconButton>

                  </>
                ) : (
                  <IconButton
                    className="StartTranscription--Button mt-3"

                    sx={{ padding: 0, background: "var(--primary-color)" }}
                    onClick={startRecording}
                    id="notetaker_start_audio_recording"
                  >
                    <div
                      style={{
                        background: "var(--primary-color)",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Mic style={{ color: "#FFF" }} />
                    </div>
                  </IconButton>
                )}


              </div>
            </div>
          </div>
        )) ||
          (step === 2 && (
            <div className="Notetaker--Content">
              <div className="Notetaker--Header d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                <h6 className="d-flex align-items-center justify-content-between">{process.env.REACT_APP_CLIENT_DISPLAY_NAME}   <IconButton className="d-lg-none" onClick={closeCustomModal}>
                  <Close />
                </IconButton></h6>
                <div className="d-flex align-items-center gap-3">
                  <Button
                    className="PrimaryCTA---ALT"
                    onClick={() => setStep(1)}
                    id="notetaker_back_to_editor"
                  >
                    {NotePadSVG}
                    Go Back to Editor
                  </Button>
                  <Button
                    className="PrimaryCTA"
                    onClick={checkTnc}
                    disabled={transcribing || transcriptionValue.trim() === ""}
                    id="notetaker_send_transcription"
                  >
                    <img
                      width={24}
                      src={"/assets/img/clinical_notes_assets/Frame-submit.png"}
                      alt="submit"
                    />
                    <span className="d-none d-lg-inline">Send Transciption</span>
                  </Button>

                  <IconButton sx={{ minWidth: "52px" }} id="close_notetaker" className=" d-none d-lg-block" onClick={closeCustomModal}>
                    <Close />
                  </IconButton>


                </div>
              </div>
              <div className="Notetaker--Body Notetaker--FileContainer">
                <div className="my-3">
                  <div className="App">
                    {loading ? (
                      <div className="progress--container">
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        <FileUploader
                          multiple={true}
                          handleChange={handleFileChange}
                          name="file"
                          types={["jpeg", "png", "jpg", "pdf"]}
                          label="Select a file or drag and drop here "
                        />
                        <p className="m-0 mt-2">
                          {" "}
                          <span>*</span> Supported file formats are JPG, JPEG,
                          PNG, and PDF.
                        </p>
                        <p className="m-0">
                          {" "}
                          <span>*</span> Maximum file size is 20MB.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {selectedFile && uploadFilePercent < 100 ? (
                <div
                  className="p-3"
                  style={{
                    borderRadius: "12px",
                    border: "1px solid #E7E7E7",
                    background: "var(--bgColor-light, #FFF)",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div>
                        <h6
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #D0D5DD",
                            padding: 5,
                          }}
                        >
                          {uploadFileDetail?.name}{" "}
                        </h6>
                        <p>{Math.round(uploadFileDetail?.size / 1024)} KB</p>
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <div>
                    <LinearProgressBarWithLabel
                      value={uploadFilePercent}
                      label={uploadFilePercent}
                    />
                  </div>
                </div>
              ) : (
                selectedFile &&
                uploadFilePercent === 100 && (
                  <div
                    className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between p-3"
                    style={{
                      borderRadius: "12px",
                      border: "1px solid #E7E7E7",
                      background: "var(--bgColor-light, #FFF)",
                    }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <h6
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #D0D5DD",
                            padding: 5,
                          }}
                          className="d-flex align-items-center justify-content-between"
                        >
                          {uploadFileDetail?.name}{" "}
                          <IconButton id="notetaker_remove_document" className="" onClick={() => { setSelectedFile(null) }}>
                            <DeleteOutlineOutlined />
                          </IconButton>
                        </h6>
                        <p>{Math.round(uploadFileDetail?.size / 1024)} KB</p>
                      </div>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      {!loading && (<>
                        <button
                          className="p-1"
                          style={{
                            borderRadius: "8px",
                            background: "var(--primary-accent-color)",
                            cursor: "pointer",
                            border: "none",
                          }}
                          disabled={transcribing}
                          onClick={handleTranscribeClick}
                          id="notetaker_transcribe_document"
                        >
                          <p className="m-0" style={{

                            color: "var(--primary-color)"
                          }}>
                            <Loop
                              className={`${transcribing ? "animate-spin" : ""
                                }`}
                            />{" "}
                            Transcribe
                          </p>
                        </button>

                      </>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
      </div>
      <DisclaimerModal
        disclaimer={disclaimer}
        close={() => setDisclaimer(true)}
        handleAccept={() => {
          window.localStorage.setItem(
            "disclaimer",
            JSON.stringify({ status: true })
          );
          handleSendData()
          setDisclaimer(false);
        }}
      />
      <AcceptCCOPDisclaimer
        open={tncDialog}
        close={() => setTncDialog(true)}
        disclaimercontent={disclaimercontent}
        handleAccept={() => {
          acceptTnc();
        }}
      />
    </>
  );
};

export default FloatingService;
