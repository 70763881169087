import {
  Close,
  CloudUploadOutlined,
  ContentCopy,
  CopyAll,
  Delete,
  Mic,
  Stop,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import React from "react";
import AudioVisualizer from "../lib/AudioVisualizer";

function NewTranscriptionDialog({ open, handleClose }) {
  return (
    <Dialog
      className="client-details-dialog"
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogTitle className="d-flex gap-2 align-items-center justify-content-between">
        <h5 className="m-0">Stella</h5>
        <div className="d-flex align-items-center gap-2 gap-md-3">
          <Button
            className="PrimaryCTA---ALT"
            id="notetaker_uplaod_document_for_transcription"
          >
            <CloudUploadOutlined />
            Upload
          </Button>
          <Button className="PrimaryCTA" id="notetaker_send_transcription">
            <img
              width={24}
              src={"/assets/img/clinical_notes_assets/Frame-submit.png"}
              alt="submit"
            />
            Send Transciption
          </Button>
        </div>
      </DialogTitle>

      <div className="p-4 pb-0">
        <div
          style={{
            width: "100%",
            boxShadow: "0px 20px 50px 0px var(--primary-color-opacity50)",
          }}
          className=" rounded-top "
        >
          <textarea
            variant="default"
            id="speakArea"
            label="Speak Now"
            style={{
              width: "100%",
              resize: "none",
              border: "none",
              maxHeight: "350px",
            }}
            className="SpeechTextArea p-2 "
            multiline
            rows={16}
            cols={10}
            fullWidth
          />
          <div className=" ">
            <IconButton>
              <ContentCopy />
            </IconButton>
            <IconButton>
              <Delete />
            </IconButton>
          </div>
        </div>
      </div>

    
      <div className="px-4 ">
        <AudioVisualizer />
      </div>
    </Dialog>
  );
}

export default NewTranscriptionDialog;
