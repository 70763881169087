import {
  Close,
  Delete,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";

function Documents({ clientDetails }) {
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debounce, setDebounce] = useState("");
  useEffect(() => {
    if (clientDetails?.id) {
      const storedData = localStorage.getItem("Authorization");
      const profile = JSON.parse(localStorage.getItem("profile"));
      const token = storedData ? storedData?.split("Bearer ")[1] : null;
      const FetchClinicalNotesClients = async (professional_id, client_id) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}clinicalnotes/list-prescriptions?count=10&page=${page}&user_id=${clientDetails?.id}&category=${selectedDocument}&search=${debounce}`,
            {
              method: "GET",
              headers: {
                "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                "Content-Type": "application/json",
                token: token,
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const resp = await response.json();
          if (resp.status === "success") {
            setDocuments(resp.data);
            setTotalPages(resp.total_pages);
          } else {
            enqueueSnackbar(resp.message, { variant: "info" });
          }
        } catch (error) {
          console.error(error);
        }
      };
      FetchClinicalNotesClients(profile.UserId, clientDetails?.id);
    }
  }, [clientDetails?.id, page, selectedDocument, debounce]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebounce(searchTerm);
    }, 500);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const DocTypes = {
    png: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/IMG2File.png`,
    doc: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/DOCFile.png`,
    excel: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/EXCELFile.png`,
    pdf: `${process.env.REACT_APP_DOC_BASE_URL}odoo/open/clinical-copilot/PDFFile.png`,
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div className="d-flex mb-3 gap-2 align-items-center">
        <TextField
          sx={{ minWidth: "150px" }}
          placeholder="Search"
          size="small"
          label=""
          value={searchTerm}
          disabled={selectedDocument}
          onChange={(e) => setSearchTerm(e.target.value)}
        />{" "}
        <Box sx={{ minWidth: "100px" }}>
          <FormControl
            size="small"
            fullWidth
            sx={{ background: "white" }}
            className=""
          >
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select
              id="basic-menu"
              onChange={(e) => {
                setSelectedDocument(e.target.value);
              }}
              label="Filter"
              value={selectedDocument}
              disabled={debounce}
            >
              <MenuItem value="prescription">Prescription</MenuItem>
              <MenuItem value="lab test">Lab Test</MenuItem>
              <MenuItem value="treatment plans">Treatment Plans</MenuItem>
              <MenuItem value="case history">Case History</MenuItem>
              <MenuItem value="health monitoring data">
                Health Monitoring Data
              </MenuItem>
              <MenuItem value="medication history">Medication History</MenuItem>
              <MenuItem value="medical image">Medical Image</MenuItem>
              <MenuItem value="surgical reports">Surgical Reports</MenuItem>
              <MenuItem value="others">Others</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {(selectedDocument || debounce) && (
          <div className="d-flex align-items-center gap-2 border rounded-3 p-2">
            {selectedDocument || debounce}

            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedDocument("");
                setSearchTerm("");
              }}
            />
          </div>
        )}
      </div>

      <div className="row">
        {documents.map((item, index) => (
          <div className=" mb-4" key={index}>
            <div className="card">
              <div className="d-flex flex-column flex-md-row gap-2 align-items-center px-md-4 p-2">
                <div className="d-flex align-items-center  w-100  gap-2 gap-md-4 ">
                  <div>
                    <img
                      src={DocTypes[item?.file_type] || DocTypes.png}
                      alt={`${item?.image_url} preview`}
                      className="card-img-top"
                      style={{
                        minWidth: "60px",
                        width: "60px",
                        height: "auto",
                      }} // Set a fixed width and auto height
                    />
                  </div>

                  <div style={{ color: "#344054" }} className="">
                    <span
                      style={{
                        fontSize: "14px",
                        background: "#DCFAE6",
                        color: "#079455",
                        border: "1px solid #079455",
                      }}
                      className="px-2 rounded-pill"
                    >
                      {item?.category_name?.charAt(0)?.toUpperCase() +
                        item?.category_name?.slice(1)}
                    </span>
                    <p className="m-0">
                      <strong>File Name:</strong> {item?.new_file_name}
                    </p>

                    <p className="m-0 ">
                      {dayjs(item?.uploaded_datetime).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  </div>
                </div>

                <Button
                  onClick={() => {
                    window.open(item?.image_url);
                  }}
                  className="PrimaryCTA--Outlined  fw-semibold"
                >
                  Preview
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {documents?.length === 0 && (
        <div className="NoDataAvailable">
          {NoDataSvg}
          <p> No Documents Available</p>
        </div>
      )}
      {documents?.length > 0 && (
        <div className="d-flex justify-content-end mb-5 p-4">
          <Box
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#var(--primary-color)", // Selected page background color
                  color: "#fff", // Selected page text color
                },
                "&:hover": {
                  backgroundColor: "var(--primary-color)", // Hover background color
                },
              },
            }}
          >
            <Pagination
              count={totalPages ? totalPages : 1}
              page={page}
              onChange={handleChange}
              renderItem={(item) => <PaginationItem {...item} />}
            />
          </Box>
        </div>
      )}
    </div>
  );
}

export default Documents;
