import { CheckCircleOutline } from "@mui/icons-material";
import React from "react";

function CautionContent() {
  return (
    <div className="mt-3">
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        The Clinical Copilot has not been designed or developed to be used as a
        medical device. Any output should be verified by a healthcare
        professional (HCP), and no direct diagnosis should be claimed.
      </p>
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        The generated output may not always be completely reliable. Due to the
        nature of large language models (LLMs) and generative AI, outputs may
        contain incorrect or biased information (e.g., stereotypes or other
        harmful content) and should be reviewed. All summaries or answers should
        be considered drafts and not final.
      </p>
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        <span>
          {" "}
          If the Clinical Copilot detects content that violates our policies,
          including the{" "}
          <a
            target="blank"
            href={`https://legal${process.env.REACT_APP_CLIENT_DOMAIN}/us/terms-and-conditions#uwc-use-of-generative-ai-technology`}
          >
            Generative AI Prohibited Use Policy
          </a>
          , a response may not be returned. Even if a response is returned, the
          user will be solely responsible for any outcomes resulting from its
          use.
        </span>{" "}
      </p>
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        When used by HCPs for Q&A purposes, the Clinical Copilot is intended
        only as an educational tool for medical training or to reinforce the
        HCP's prior training.
      </p>
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        LLM output may not follow the exact format laid out in the prompt. The
        prompt design to extract information for each field should account for
        potential deviations in format (e.g., dashes in field names, exact
        capitalization of letters).
      </p>
      <p className="d-flex  gap-2">
        <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        <span>
          By using the Clinical Copilot, it is acknowledged that the{" "}
          <a
            target="blank"
            href={`https://legal${process.env.REACT_APP_CLIENT_DOMAIN}/disclaimer-ccop`}
          >
            Disclaimer for {process.env.REACT_APP_CLIENT_NAME} Clinical Copilot
          </a>{" "}
          has been read and understood.
        </span>
      </p>
    </div>
  );
}

export default CautionContent;
