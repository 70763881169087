import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { validate_token } from "../../core/_request";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import Cookies from "js-cookie";

function ClinicalUserAuthentication() {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const authtoken = Cookies.get("authToken")
  const userRole = `${Cookies.get("authUserRole")}`
  // useEffect(() => {
  //   if (authtoken && userRole) {
  //     navigate("/clinical/home");
  //   }
  // }, []);


  useEffect(() => {

    if (authtoken && userRole) {
      if (userRole.includes("copilot")) {
        validate_token(authtoken).then((resp) => {
          if (resp?.ResponseCode === 200) {
            localStorage.setItem("Authorization", resp?.data?.usertoken);
            localStorage.setItem(
              "profile",
              JSON.stringify(resp?.data?.userdetails)
            );
            let now = new Date();
            let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
            let firstName = resp?.data?.userdetails?.Name.split(" ")
              .slice(0, 1)
              .join("");
            document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=${process.env.REACT_APP_CLIENT_DOMAIN}/us;path=/;`;
            document.cookie = `userType=copilot;expires=${oneYearFromNow.toUTCString()};domain=${process.env.REACT_APP_CLIENT_DOMAIN}/us;path=/;`;
            navigate("/clinical/home");
          } else {
            navigate("/unauthorize")
          }
        });
      } else {
        navigate("/unauthorize")
      }
    } else {
      handleClick()
    }

  }, [userRole, authtoken]);
  const handleClick = () => {
    window.location.href = `https://accounts${process.env.REACT_APP_CLIENT_DOMAIN}/?redirect=${window.location.href}`;
  };
  return (
    <div
      style={{ height: "100vh" }}
      className="w-full d-flex gap-4 justify-content-center align-items-center flex-column"
    >

      <img     height={60} src={process.env.REACT_APP_CLIENT_IMAGE} />
      <div className="d-flex flex-column gap-3 align-items-center">
        <p className=" fw-bold display-4">Welcome</p>
        {authtoken ? (
          <p
            style={{ maxWidth: "500px", textAlign: "center" }}
            className=" fs-5"
          >
            Login Successful!
          </p>
        ) : (
          <p
            style={{ maxWidth: "500px", textAlign: "center" }}
            className=" fs-5"
          >
            You must be logged in to access the Clinical Note. Please log in to
            your account to proceed.
          </p>
        )}
      </div>

      {!authtoken && (
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{ minWidth: "300px", fontSize: "20px" }}
          className="PrimaryCTA "
        >
          Login
        </Button>
      )}
    </div>
  );
}

export default ClinicalUserAuthentication;
